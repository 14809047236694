import React from 'react';
import _ from 'lodash';
import Slider from "react-slick";
import anime from 'animejs';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Layout } from '../components/index';
import { safePrefix, markdownify, Link } from '../utils';

export default class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.topContainer = React.createRef();
        this.bottomContainer = React.createRef();
        this.state = {
            coverSrc: _.get(this.props, 'pageContext.frontmatter.img.thumbnail')[0]
        }
        this.locale = _.get(this.props, 'pageContext.locale');
    }

    componentDidMount() {
        anime.timeline({
            easing: 'easeInOutExpo',
        })
            .add({
                easing: 'easeInOutExpo',
                targets: this.topContainer.current,
                translateY: ['50px', '0px'],
                opacity: [0, 1],
            })
            .add({
                easing: 'easeInOutExpo',
                targets: this.bottomContainer.current,
                translateY: ['50px', '0px'],
                opacity: [0, 1],
            });
    }

    thumbnailClickHandler(thumbnail, index) {
        const thumbnails = document.getElementsByClassName('product-thumnail-container');
        Object.keys(thumbnails).map(idx => (thumbnails[idx].classList.remove('active')));
        document.getElementsByClassName(`product-thumnail-container-${index}`)[0].classList.add('active');
        this.setState({ coverSrc: thumbnail });
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            vertical: false,
            arrows: false
        };
        return (
            <Layout {...this.props}>
                <div className="product-top-container" ref={this.topContainer}>
                    <div className="product-left-container">
                        <div className="product-cover" style={{ backgroundImage: `url('${safePrefix(this.state.coverSrc)}')` }} />
                        <div className="product-thumbnail-slider">
                            {
                                _.get(this.props, 'pageContext.frontmatter.img.thumbnail').length <= 4 ?
                                    <div className="no-slick">
                                        {
                                            _.get(this.props, 'pageContext.frontmatter.img.thumbnail').map((thumbnail, index) =>
                                                <Thumbnail key={index} className={`product-thumnail-container-${index} ${index === 0 ? 'active' : ''}`} clickHandler={() => this.thumbnailClickHandler(thumbnail, index)} src={safePrefix(thumbnail)} />
                                            )
                                        }
                                    </div> :
                                    <Slider {...settings}>
                                        {
                                            _.get(this.props, 'pageContext.frontmatter.img.thumbnail').map((thumbnail, index) =>
                                                <Thumbnail key={index} className={`product-thumnail-container-${index} ${index === 0 ? 'active' : ''}`} clickHandler={() => this.thumbnailClickHandler(thumbnail, index)} src={safePrefix(thumbnail)} />
                                            )
                                        }
                                    </Slider>
                            }
                        </div>
                    </div>
                    <div className="product-right-container">
                        <div className="product-title">{_.get(this.props, `pageContext.frontmatter.name.${this.locale}`)}</div>
                        <div className="product-subTitle">{_.get(this.props, `pageContext.frontmatter.subTitle.${this.locale}`)}</div>
                        <div className="product-description">
                            <div className="product-description-header">
                                {_.get(this.props, `pageContext.site.siteMetadata.product.description.${this.locale}`)}
                            </div>
                            <div className="product-description-content">
                                {markdownify(_.get(this.props, `pageContext.frontmatter.description.${this.locale}`))}
                            </div>
                        </div>
                        <div className="product-specification">
                            <div className="product-specification-header">
                                {_.get(this.props, `pageContext.site.siteMetadata.product.specification.${this.locale}`)}
                            </div>
                            <div className="product-description-content">
                                {
                                    _.get(this.props, `pageContext.frontmatter.specifications`).map(specification => (
                                        <div>
                                            {markdownify(specification[this.locale])}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="product-price">
                            <div className="product-price-header">
                                {_.get(this.props, `pageContext.site.siteMetadata.product.price.${this.locale}`)}
                            </div>
                            <div className="product-price-content">
                                {_.get(this.props, `pageContext.frontmatter.price.${this.locale}`)}
                            </div>
                        </div>
                        <Link to={_.get(this.props, `pageContext.site.siteMetadata.footer.socialMedia.whatsapp`)}>
                            <div className="product-order-button-wrapper">
                                <button className="product-order-button green-btn">{_.get(this.props, `pageContext.site.siteMetadata.product.orderBtn.${this.locale}`)}</button>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="product-bottom-container" ref={this.bottomContainer}>
                    {
                        _.get(this.props, 'pageContext.frontmatter.img.other').map((src, index) =>
                            <div className="product-other-img-wrapper">
                                <LazyLoadImage key={index} src={safePrefix(src)} />
                            </div>
                        )
                    }
                </div>
            </Layout >
        );
    }
}

const Thumbnail = (props) => {
    const { src, clickHandler, className } = props;
    return (
        // <div onClick={clickHandler} className={`product-thumnail-container ${className}`} style={{ backgroundImage: `url('${src}')` }} />
        <LazyLoadImage onClick={clickHandler} className={`product-thumnail-container ${className}`} effect="blur" src={src} />
    );
};